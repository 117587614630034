<template>

  <section id="encurso-tramo"> 
    <div v-if="selectedTramo.transporte_solicitud && selectedTramo.transporte_solicitud.length > 0" class="row">
      <div class="col-12">
        <h6 class="pb-2 font-main color-gray border-bottom d-flex align-items-center justify-content-between">
          <span>
            <font-awesome-icon 
              icon="pen-to-square" 
              class="color-secondary"
            />
            Inscripciones 
          </span>
          <span class="badge badge-custom badge-custom-green-light fs-6">
            {{ selectedTramo.transporte_solicitud.length }} 
          </span>
        </h6>
        <ul class="list-group list-group-flush">
          <li 
            v-for="(solicitud, i) in selectedTramo.transporte_solicitud"
            :key="i"
            class="list-group-item px-0 py-2">
            <div class="row">
              <div class="col-12 col-lg-10">
                <p class="text-secondary mb-0">
                  <font-awesome-icon 
                    icon="circle-user" 
                    class="color-secondary"
                  />
                  {{ solicitud.usuario.primer_nombre }}
                  {{ solicitud.usuario.apellido_paterno }}
                </p>
                <span class="badge badge-custom badge-custom-green-light font-size-14 me-1">
                  <font-awesome-icon 
                    icon="couch" 
                  />
                  {{ solicitud.transporte_asiento.nombre_asiento }}
                </span>
                <span class="badge badge-custom border text-secondary font-size-14 me-1">
                  <font-awesome-icon 
                    icon="envelope" 
                    class="color-light"
                  />
                  {{ solicitud.usuario.correo }}
                </span>
                <p class="text-black-50 lh-sm mb-0">
                    <small>
                      <font-awesome-icon 
                          :icon="['fa-regular', 'calendar']" 
                          class="color-green me-1"
                        />
                      Solicitado: {{ dateFormatymd(solicitud.fecha_creacion) }}
                    </small>
                </p>
              </div>
              <div class="col-12 col-lg-2 d-flex justify-content-end">
                <div 
                  v-if="selectedTramo.transporte_solicitud.length > 0"
                  class="dropdown dropdown-custom d-flex justify-content-center align-items-start">
                  <button 
                    @click="setSelectedTramoData(solicitud)"
                    class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                    type="button"
                    data-bs-toggle="dropdown">
                    <font-awesome-icon icon="ellipsis-vertical"/>
                  </button>
                  <ul 
                    class="dropdown-menu"
                  >
                    <li>
                      <a 
                        @click="updateModalData()"
                        data-bs-toggle="modal" 
                        data-bs-target="#modal-detalle-inscripcion"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-brown" icon="pencil"/>
                        Editar
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="deleteSolicitud(solicitud)"
                        class="dropdown-item"
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="showAlertStart" class="row">
      <div class="col-12">
        <div class="alert alert-info text-center mb-0" role="alert">
          <font-awesome-icon icon="circle-arrow-left"/>
          Selecciona un tramo para ver el detalle.
        </div>
      </div>
    </div>

    <div v-if="showAlertSelected" class="row">
      <div class="col-12">
        <div class="alert alert-warning text-center mb-0" role="alert">
          <font-awesome-icon icon="circle-info"/>
          Tramo seleccionado sin inscripciones.
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      id="modal-detalle-inscripcion" 
      tabindex="-1" 
    >
      <div class="modal-dialog modal-lg">
        <EncursoDetalle 
          v-if="modal_detail"
          @close-modal-detail="closeModalDetail"
          @update-data="updateModalData"
          @update="parentData = $event"
        />
      </div>
    </div>

    <Spinner v-if="show_spinner"/>

  </section>

</template>

<script>
import EncursoDetalle from "./EncursoDetalle.vue";
import Spinner from "../Spinner.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { 
    EncursoDetalle,
    Spinner 
  },

  data() {
    return {
      modal_detail: false
    }
  },

  props: {
    show_spinner: {
      type: Boolean
    },
  },

  computed: {
    ...mapState("transporteModule", ["selectedTramo"]),

    showAlertStart() {
        return Object.keys(this.selectedTramo).length === 0 || Object.keys(this.selectedTramo).length === 0 && Object.keys(this.selectedTramo.transporte_solicitud).length === 0;
    },
    showAlertSelected() {
        return Object.keys(this.selectedTramo).length !== 0 && Object.keys(this.selectedTramo.transporte_solicitud).length === 0;
    }
  },

  methods: {
    ...mapActions("transporteModule", ["setSelectedSolicitudAction", "deleteSolicitudAction"]),

    setSelectedTramoData(inscripcion) {
      this.setSelectedSolicitudAction(inscripcion);
    },
    
    closeModalDetail() {
      this.modal_detail = false;
    },

    updateModalData() {
      this.modal_detail = true;
      //this.$emit("update-modal-data");
    },

    async deleteSolicitud(solicitud) {
      const data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idSolicitud: solicitud.id_solicitud,
        idUsuario: solicitud.usuario.id_usuario,
      };
      await this.deleteSolicitudAction(data);
      this.toastSolicitudDeleteSuccess();
    },

    toastSolicitudDeleteSuccess() {
      this.$toast.open({
        message: 'La solicitud ha sido eliminada correctamente.',
        type: 'success',
        duration: 5000,
        position: 'top-right'
      }); 
    }
  },
};
</script>
