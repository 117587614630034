<template>
  <section id="en-curso">
    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 my-3">
          <i class="fa-solid fa-bus-simple color-secondary"></i>
          Selecciona el tramo de tu interés y revisa el detalle en el costado derecho.
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-7 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 mb-3 mb-xl-0 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar un tramo" 
                v-model.trim="query_texto" 
                @keyup="filterByText()"/>
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col-6 col-lg-4">
            <v-select
              class="selvue-custom"
              :clearable="false"
              :options="plan_status" 
              v-model="cat_selected"
              @input="filterByStatus(cat_selected)"
              placeholder="Filtrar por categoría">
              <span slot="no-options"></span>
            </v-select>     
          </div>
          <div class="col-6 col-lg-4">
            <v-select
              class="selvue-custom"
              :clearable="false"
              label="nombre_planificacion"
              :options="planificaciones_list" 
              v-model="planificacion_selected"
              @input="filterByPlanification(planificacion_selected)"
              placeholder="Filtrar por planificación">
              <span slot="no-options"></span>
            </v-select>     
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5 mb-3">
        <div class="row">
<!--           <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
              icon="filter" 
              class="color-secondary pe-2"
            />
            Filtrar
          </div> -->
          <div class="col-12">
            <div class="row" v-click-outside="hideCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    v-show="init_date != 'Inicio'"
                    @click="clearDates()"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    v-show="end_date != 'Término'"
                    @click="clearDates()"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-7 mb-3 mb-lg-0">
        <div class="card card-custom card-shadow border-round-10 border-0">
              <div class="card-body">
                <EncursoPlanificacion
                  :plannedTramosList="tramosPlanificados"
                  :tramosType="query_id_categoria" 
                  :show_spinner="spinner_izquierdo" 
                  @change-tramo="setSelectedTramoData"
                />
              </div>
        </div>

        <div class="users-list__pagination" v-if="pagination.page_count > 1">
          <paginate
            v-model="pagination.actual_page"
            :page-count="pagination.page_count"
            :page-range="pagination.page_range"
            :click-handler="pagination.click_handler"
            :prev-text="pagination.prev_text"
            :next-text="pagination.next_text"
            :container-class="pagination.container_class"
            :page-class="pagination.page_class"
          />
        </div>

      </div>

      <div class="col-12 col-lg-5">
        <div class="card card-custom card-shadow border-round-10 border-0">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <div>
                    <h5 class="font-main-bold color-gray mb-0"> 
                      <i class="fa-solid fa-circle-info color-secondary"></i>
                      Información<br>
                      <small class="color-main">
                        {{ obtenerFecha(queryTramos.fechaViaje) }}
                      </small>
                    </h5>
                  </div>
                  <div>
                    <export-json-excel 
                      class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
                      v-if="selectedTramo.transporte_solicitud && selectedTramo.transporte_solicitud.length > 0"
                      :data="exported_data" 
                      :fields="exported_fields"
                      name="Detalle Planificación" 
                      :beforeExport="download"
                    >
                      <i class="fa-solid fa-download pe-2"></i>
                      Descargar
                    </export-json-excel>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <EncursoTramo 
                      :show_spinner="spinner_derecho" 
                      @open-modal="openModalDetail"
                    />
                  </div>
                </div>

              </div>
        </div>
      </div>
    </div>

  </section>

</template>

<script>
import EncursoPlanificacion from "./EncursoPlanificacion.vue";
import EncursoTramo from "./EncursoTramo.vue";
import { mapActions, mapState } from "vuex";
import "export-json-excel";

export default {
  components: { EncursoPlanificacion, EncursoTramo },

  data() {
    return {
      spinner_derecho: false,
      spinner_izquierdo: false,
      // Filtro texto
      query_texto: "",
      // FIltro estado
      cat_selected: "Activo",
      planificacion_selected: "Filtrar por planificación",

      query_id_planificacion: "0",
      
      plan_status: ["Activo", "Programado", "Finalizado"],
      query_id_categoria: "1",
      // Filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",
      // Calendario
      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      end_calendar: false, //termino
      query_fecha_inicio: "",
      query_fecha_fin: "",

      img_dowload_w: require("@/assets/img/utilities/dowload-w.svg"),
      img_dowload_b: require("@/assets/img/utilities/dowload-b.svg"),

      queryTramos: {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        fechaViaje: "",
        idPlanificacion: "",
      },

      exported_fields: [
        { title: "Nombre", name: "nombre" }, 
        { title: "Apellidos", name: "apellidos" }, 
        { title: "RUT", name: "rut" },
        { title: "Cargo", name: "cargo" }, 
        { title: "Gerencia", name: "gerencia" }, 
        { title: "Teléfono", name: "telefono" }, 
        { title: "Correo", name: "correo" }, 
        { title: "Tipo viaje", name: "tipo_viaje" }, 
        { title: "Tramo", name: "tramo" },
        { title: "Asiento", name: "asiento" },
        { title: 'Empresa', name: 'empresa' }, 
        { title: 'Sucursal', name: 'sucursal' }, 
        { title: 'Turno', name: 'turno' }, 
        { title: 'Hora viaje', name: 'hora_viaje' },
        { title: 'Fecha viaje', name: 'fecha_viaje' },
        { title: 'Transporte', name: 'nombre_transporte' },
        { title: 'Tipo transporte', name: 'tipo_transporte' },
        { title: 'Planificación', name: 'planificacion' },
      ],

      exported_data: [],
      excel: {},
      tramosPlanificados: [],

      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class:"pagination-item"
      },
      tramosLimit: "15",
      timer: null
    };
  },

  computed: {
    ...mapState("transporteModule", [
      "plannedTramos", 
      "plannedTramosPagination", 
      "selectedTramo",
      "planificaciones_list"
    ]),
    
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    }
  },

  async created() {
    await this.searchTramos();

    const empresa = {
      idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
    };
    await this.getTurnos(empresa); // para el modal detalle
    await this.getTeams(empresa); // para el modal detalle
    await this.getAllPlanificaciones(empresa);
  },

  methods: {
    ...mapActions("transporteModule", [
      "getPlannedTramosAction", 
      "getPlannedTramosPagination", 
      "setSelectedTramoAction",
      "getAllPlanificaciones", 
      "getTurnos",
      "getTeams",
      "resetSelectedTramo"
    ]),

    obtenerFecha(fecha) {
      if (fecha == "") return "";
      let semana = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      fecha = new Date(fecha + "T00:00:00");
      let diaSemana = fecha.getDay();
      let dia = fecha.getDate();
      let mes = fecha.getMonth();
      return `${semana[diaSemana]} ${dia} de ${meses[mes]}`;
    },

    formatToday() {
      let hoy = new Date();
      return hoy.toISOString();
    },

    async searchTramos(){
      this.resetSelectedTramo();
      await this._getTramosPagination();
      if (this.plannedTramosPagination.length > 0) {
        this.pagination.actual_page = 1;
        await this._getPlannedTramosByPage(this.pagination.actual_page);
      } else {
        this.tramosPlanificados = [];
      }
    },
    
    async changePage(page) {
      this.pagination.actual_page = page;
      await this._getPlannedTramosByPage(this.pagination.actual_page);
    },

    async _getTramosPagination() {
      this.spinner_izquierdo = true;
      const data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        limit: this.tramosLimit, 
        tipoRango: this.query_id_categoria, 
        nombre: this.query_texto, 
        fechaInicio: this.query_fecha_inicio,
        fechaFin: this.query_fecha_fin,
        idPlanificacion: this.query_id_planificacion
      };
      await this.getPlannedTramosPagination(data);
      this.spinner_izquierdo = false;
      this.pagination.page_count = this.plannedTramosPagination.length;
    },

    async _getPlannedTramosByPage(page) {
      this.spinner_izquierdo = true;
      const data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idTramos: this.plannedTramosPagination[page-1].planificaciones
      };
      await this.getPlannedTramosAction(data);
      this.tramosPlanificados = this.plannedTramos;
      this.spinner_izquierdo = false;
    },

    async setSelectedTramoData(tramo) {
      console.log("TRAMO:", tramo);
      this.setSelectedTramoAction(tramo);
    },

    openModalDetail() {
      this.modal_detail = true;
    },

    closeModalDetail() {
      this.modal_detail = false;
    },

    // Filtro calendar
    hideCalendar() {
      this.show_calendar = false;
    },

    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        if (this.end_calendar == true) {
          this.max_date = this.query_fecha_fin;
        }
      }
      if (this.calendar_side == "end-date") {
        this.max_date = "";
        if (this.start_calendar == true) {
          this.min_date = this.query_fecha_inicio;
        }
      }
    },

    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.start_calendar = true;
        this.query_fecha_inicio = this.get_date_start(day.id);
        this.init_date = day.id;
      }
      if (this.calendar_side == "end-date") {
        this.end_calendar = true;
        this.query_fecha_fin = this.get_date_end(day.id);
        this.end_date = day.id;
      }

      if (this.fecha_incio !== "" && this.query_fecha_fin !== "") this.filterByDates();
      this.show_calendar = false;
    },

    get_date_start(day) {
      let dia = day.toString() + "T00:00:00";
      return dia;
    },

    get_date_end(day) {
      let dia = day.toString() + "T23:59:59";
      return dia;
    },

    clearDates(){
      this.end_date = 'Término';
      this.init_date = 'Inicio';
      this.query_fecha_inicio = "";
      this.query_fecha_fin = "";
      this.show_calendar = false;
      this.searchTramos();
    },

    filterByStatus(status) {
      if (status == "Activo") this.query_id_categoria = "1";
      if (status == "Programado") this.query_id_categoria = "2";
      if (status == "Finalizado") this.query_id_categoria = "3";
      this.cat_selected = status;
      this.searchTramos();
    },

    async filterByText() {
      // Debounce de casi 1s para esperar que usuario complete su input
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.searchTramos();
      }, 700);
    },

    filterByPlanification(plan) {
      this.query_id_planificacion = plan.id_planificacion;
      this.searchTramos();
    },

    filterByDates() {
      this.searchTramos();
    },

    fill(tramos) {
      let usuarios = [];
      tramos.forEach((tramo) => {
        tramo.transporte_solicitud.forEach((solicitud) => {
          let tipo = solicitud.transporte_ida_vuelta.nombre_idea_vuelta == "Ida" ? "Solo ida" : "Solo vuelta";
          usuarios.push({
            nombre: solicitud.usuario.primer_nombre,
            apellidos: solicitud.usuario.apellido_paterno + " " + solicitud.usuario.apellido_materno,
            rut: solicitud.usuario.rut_usuario + "-" + solicitud.usuario.dv_usuario,
            cargo: solicitud.usuario.cargo[0].nombre_cargo,
            gerencia: solicitud.usuario.gerencia[0].nombre_gerencia,
            telefono: solicitud.usuario.telefono_movil,
            correo: solicitud.usuario.correo,
            tipo_viaje: tipo,
            tramo: tramo.transporte_lista_destino.nombre_origen + "/" + tramo.transporte_lista_destino.nombre_destino,
            asiento: solicitud.transporte_asiento.nombre_asiento,
            id_usuario: solicitud.usuario.id_usuario,
            usuario: solicitud.usuario,
          });
        });
      });
      return usuarios;
    },

    download() {
      this.exported_data = this.getDataForExport();
    },

    getDataForExport() {
      const info_solicitudes = [];
      this.selectedTramo.transporte_solicitud.forEach(inscripcion => {
        const tipo = inscripcion.transporte_ida_vuelta.nombre_idea_vuelta == 'Ida'? 'Solo ida' : 'Solo vuelta';
        info_solicitudes.push({
          nombre: inscripcion.usuario.primer_nombre,
          apellidos: inscripcion.usuario.apellido_paterno + ' ' +inscripcion.usuario.apellido_materno,
          rut: inscripcion.usuario.rut_usuario + '-' + inscripcion.usuario.dv_usuario,
          cargo: inscripcion.usuario.cargo[0].nombre_cargo,
          gerencia: inscripcion.usuario.gerencia[0].nombre_gerencia,
          telefono: inscripcion.usuario.telefono_movil,
          correo: inscripcion.usuario.correo,
          tipo_viaje: tipo,
          tramo: this.selectedTramo.transporte_lista_destino.nombre_origen + '-' + this.selectedTramo.transporte_lista_destino.nombre_destino,
          asiento: inscripcion.transporte_asiento.nombre_asiento,
          empresa: inscripcion.usuario.empresa[0].nombre_empresa,
          sucursal: inscripcion.usuario.sucursal[0].nombre_sucursal,
          turno: inscripcion.usuario.turno[0].nombre_turno,
          team: inscripcion.usuario.team.nombre_team,
          hora_viaje: this.selectedTramo.hora_viaje,
          nombre_transporte: this.selectedTramo.transporte_lista_destino.transporte_tipo_medio.nombre_medio,
          tipo_transporte: this.selectedTramo.transporte_lista_destino.transporte_tipo_medio.transporte_tipo.nombre_transporte,
          planificacion: this.selectedTramo.transporte_planificacion.nombre_planificacion,
          fecha_viaje: this.selectedTramo.fecha_viaje
        });
      })

      return info_solicitudes;
    },
  }
};
</script>