<template>
  <section id="encurso-planificacion">

    <div class="row">
      <div class="col-12">
        <h5 class="font-main-bold color-gray" >
          <i class="fa-solid fa-calendar-days color-secondary"></i>
          Tramos planificados
        </h5>
      </div>
    </div>

    <div class="row card-custom-scroll">
      <div class="col-12">
        <div class="list-group list-group-flush">
          <a 
            v-for="(tramo, i) in plannedTramosList" 
            :key="i" 
            href="javascript:"
            class="list-group-item list-group-item-action"
          >
            <div class="d-flex align-items-top">
              <div class="flex-shrink-0">
                <input 
                  :id="i"
                  class="form-check-input me-1"
                  type="radio" 
                  name="radioTramo" 
                  @change="changeSelectedTramo(tramo)"
                >
              </div>
              <div class="flex-grow-1 ms-3">
                <label :for="i" class="form-check-label stretched-link w-100 link-cursor">
                  <div class="row">
                    <div class="col-12 col-xl-7">
                      <font-awesome-icon 
                          :icon="['fa-regular', 'calendar']" 
                          class="color-green me-1"
                        />
                      <span class="text-secondary">
                        {{ formatFecha(tramo.fecha_viaje) }}
                      </span>
                      <p class="mb-0 text-secondary">
                        <span class="badge badge-custom fs-6 border text-secondary">
                          {{ tramo.transporte_lista_destino.nombre_origen }}
                        </span>
                        <font-awesome-icon icon="circle-right" class="color-main mx-1"/>
                        <span class="badge badge-custom fs-6 border text-secondary">
                        {{ tramo.transporte_lista_destino.nombre_destino }}
                        </span>
                      </p>
                    </div>
                    <div class="col-12 col-xl-5 text-end">
                      <span class="badge text-bg-secondary fs-6 me-2">
                        {{ tramo.asientos_tomados }}/{{ tramo.asientos_totales }}
                      </span>                   
                      <span v-if="tramosType == '1'" class="badge badge-custom fs-6 badge-custom-green-light">
                        Activo
                      </span>
                      <span v-if="tramosType == '2'" class="badge badge-custom fs-6 badge-custom-blue-light">
                        Programado
                      </span>
                      <span v-if="tramosType == '3'" class="badge badge-custom fs-6 badge-custom-red-light">
                        Finalizado
                      </span>
                    </div>
                  </div>
                  <div class="h4 pb-2 mb-2 text-danger border-bottom"></div>
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <p class="mb-0 text-black-50 font-size-14">
                        Inscripciones<br>
                        <font-awesome-icon :icon="['fa-regular', 'calendar-plus']" class="color-green"/>
                        {{ formatFecha(tramo.fecha_inicio_inscripcion) }}<br> 
                        <font-awesome-icon :icon="['fa-regular', 'calendar-xmark']" class="color-red"/>
                        {{ formatFecha(tramo.fecha_fin_inscripcion) }}
                      </p>
                    </div>
                    <div class="col-12 col-xl-6 text-end">
                      <p class="mb-0 text-black-50 font-size-14">
                        <font-awesome-icon icon="bus-simple" class="color-light"/> 
                        Planificación<br> 
                        <span class="badge badge-custom text-secondary font-size-14 border">
                          {{ tramo.transporte_planificacion.nombre_planificacion }}
                        </span>
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div 
      class="transporte-container__no-data" 
      v-if="(plannedTramosList == undefined) || (plannedTramosList.length == 0)"
    >
      <p>Sin resultados de busqueda.</p>
    </div>
    <Spinner v-if="show_spinner" />
  </section>
</template>

<script>
import Spinner from "../Spinner.vue";
export default {
  components: { Spinner },

  props: {
    show_spinner: {
      type: Boolean,
    },
    plannedTramosList: {
      type: Array,
    },
    tramosType: {
      type: String
    }
  },

  data () {
    return {
      semana: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      meses: [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ]
    };
  },

  methods: {
    formatFecha(fechaString) {
      const fecha = new Date(fechaString+"T00:00:00");
      return `${this.semana[fecha.getDay()]} ${fecha.getDate()} de ${this.meses[fecha.getMonth()]}`;
    },

    fechaCierreInscripcion(tramo) {
      const period = +tramo.tranpsorte_planificacion_detalle.concurrencia_ejecucion_semanal;
      const ws_limitRegistrationDay = +tramo.tranpsorte_planificacion_detalle.final_periodo_semanal;
      const limitRegistrationDay = ws_limitRegistrationDay === 7 ? 0 : ws_limitRegistrationDay;
      const periodStartDate = new Date(tramo.tranpsorte_planificacion_detalle.fecha_inicio_ejecucion+"T00:00:00");
      const periodFinishDate = new Date(tramo.tranpsorte_planificacion_detalle.fecha_final_ejecucion+"T00:00:00");
      const fechaInscripcion = this.getRegistrationDate(limitRegistrationDay, periodStartDate, periodFinishDate, period);
      const diaSemana = fechaInscripcion.getDay();
      const dia = fechaInscripcion.getDate();
      const mes = fechaInscripcion.getMonth();
      return `${this.semana[diaSemana]} ${dia} de ${this.meses[mes]}`;
    },

    getRegistrationDate(limitRegistrationDay, periodStartDate, periodFinishDate, period) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (today.getTime() < periodStartDate.getTime()) {
        return this._getFirstRegDate(limitRegistrationDay, periodStartDate);
      } else if (today.getTime() > periodFinishDate.getTime()) {
        return this._getLastRegDate(limitRegistrationDay, periodStartDate, periodFinishDate, period);
      } else {
        let regDate = this._getFirstRegDate(limitRegistrationDay, periodStartDate);
        let nextRegDate = new Date(regDate);
        nextRegDate.setDate(regDate.getDate() + period * 7);
        if (nextRegDate.getTime() > today.getTime()) {
          return nextRegDate;
        } else {
          return this.getRegistrationDate(
            limitRegistrationDay,
            new Date(periodStartDate.setDate(periodStartDate.getDate() + period * 7)),
            periodFinishDate,
            period
          );
        }
      }
    },

    ratio(tramo) {
      let ocupados = parseInt(tramo.asientos_tomados);
      let total = parseInt(tramo.asientos_totales);
      if (total == 0) {
        return "0";
      }
      let porciento = (ocupados / total) * 100;
      porciento = porciento.toFixed(2);
      porciento = parseFloat(porciento);
      return porciento;
    },

    changeSelectedTramo(tramo) {
      this.$emit("change-tramo", tramo);
    },

    _getFirstRegDate(limitRegistrationDay, periodStartDate) {
      let currentDate = new Date(periodStartDate);
      while (currentDate.getDay() !== limitRegistrationDay) {
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
      }
      return currentDate;
    },

    _getLastRegDate(limitRegistrationDay, periodStartDate, periodFinishDate, period) {
      let regDate = this._getFirstRegDate(limitRegistrationDay, periodStartDate);
      let nextRegDate = new Date(regDate);
      nextRegDate.setDate(regDate.getDate() + period * 7);
      if (nextRegDate.getTime() > periodFinishDate.getTime()) {
        return regDate;
      } else {
        return this._getLastRegDate(
          limitRegistrationDay,
          new Date(periodStartDate.setDate(periodStartDate.getDate() + period * 7)),
          periodFinishDate,
          period
        );
      }
    },
  },
  watch: {
    plannedTramosList(value) {
      let largo = value.length;
      for (var i = 0; i <= largo; i++) {
        const element = document.getElementById(i);
        if(element) {
          element.checked = false;
        }
      }
    }
  }
};
</script>